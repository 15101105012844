// MUI Components
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const SnackbarMessage = ({
  open,
  onClose,
  success,
  text,
  autoHideDuration = 3000,
  position = { vertical: "bottom", horizontal: "left" },
}) => (
  <Snackbar
    open={open}
    autoHideDuration={autoHideDuration}
    onClose={onClose}
    anchorOrigin={position}
  >
    {text && (
      <Alert
        onClose={onClose}
        severity={success ? "success" : "error"}
        sx={{ width: "100%" }}
      >
        {text}
      </Alert>
    )}
  </Snackbar>
);

export default SnackbarMessage;
