import { useAuth0 } from "@auth0/auth0-react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { getProgressAnalysisStatus, transferExam } from "../services/Exam";
import useClinic from "../hooks/useClinic";
import { ToastContext } from "./ToastContext";
import { useTranslation } from "react-i18next";

const ExamTransferContext = createContext({
  openExamTransferModal: false,
  transferPatient: null,
  basePatientId: null,
  //   setOpenExamTransferModal: () => {},
  setTransferPatient: () => {},
  setBasePatientId: () => {},
  handleExamTransferClick: () => {},
  handleExamTransferClose: () => {},
  handleExamTransfer: () => {},
});

const ExamTransferProvider = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();
  const { setToast } = useContext(ToastContext);
  const { clinicId } = useClinic();
  const [openExamTransferModal, setOpenExamTransferModal] = useState(false);
  const [selectedExamTransfer, setSelectedExamTransfer] = useState(null);
  const [transferPatient, setTransferPatient] = useState(null);
  const [basePatientId, setBasePatientId] = useState("");
  const [progressionAnalysisFound, setProgressionAnalysisFound] =
    useState(false);

  const handleGetProgressionAnalysis = useCallback(
    async (patientId, selected) => {
      const token = await getAccessTokenSilently();
      const getAnalysisStatus = await getProgressAnalysisStatus(
        token,
        clinicId,
        patientId,
        selected?.id
      );

      if (Array.isArray(getAnalysisStatus?.data)) {
        if (getAnalysisStatus?.data?.length === 0) {
          console.log("No Analysis Found");
          setProgressionAnalysisFound(false);
        } else if (getAnalysisStatus?.data?.length > 0) {
          console.log("Analysis Found");
          setProgressionAnalysisFound(true);
        }
      }

      console.log({ getAnalysisStatus });
    },
    [getAccessTokenSilently, clinicId]
  );
  const handleExamTransferClick = useCallback(
    async (selected, patientId) => {
      console.log({ selected });
      await handleGetProgressionAnalysis(patientId, selected);
      setSelectedExamTransfer(selected);
      setBasePatientId(patientId);
      setOpenExamTransferModal(true);
    },
    [handleGetProgressionAnalysis]
  );

  const handleExamTransferClose = useCallback((event, reason) => {
    if (reason !== "backdropClick") {
      setOpenExamTransferModal(false);
    }
  }, []);

  const handleExamTransfer = useCallback(async () => {
    setOpenExamTransferModal(false);
    try {
      const token = await getAccessTokenSilently();
      await transferExam(
        token,
        clinicId,
        basePatientId,
        selectedExamTransfer?.id,
        transferPatient?.id
      );
      setToast({
        success: true,
        message: t("exam_transfer_modal_success"),
      });
    } catch (e) {
      setToast({
        success: false,
        message: t("exam_transfer_modal_failure"),
      });
    }
  }, [
    transferPatient,
    basePatientId,
    selectedExamTransfer,
    getAccessTokenSilently,
    clinicId,
    t,
    setToast,
  ]);

  useEffect(() => {
    if (!openExamTransferModal) {
      setSelectedExamTransfer(null);
      setTransferPatient(null);
      setBasePatientId("");
    }
  }, [openExamTransferModal]);
  return (
    <ExamTransferContext.Provider
      value={{
        openExamTransferModal: openExamTransferModal,
        transferPatient: transferPatient,
        basePatientId: basePatientId,
        setTransferPatient: setTransferPatient,
        setBasePatientId: setBasePatientId,
        handleExamTransfer: handleExamTransfer,
        handleExamTransferClick: handleExamTransferClick,
        handleExamTransferClose: handleExamTransferClose,
        progressionAnalysisFound: progressionAnalysisFound,
      }}
    >
      {children}
    </ExamTransferContext.Provider>
  );
};

export { ExamTransferContext, ExamTransferProvider };
