import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

export default function ProgressionChart({ chartData }) {
  const { t } = useTranslation();

  return (
    <Box sx={{ padding: 1 }}>
      <Typography variant="h6">{t("Progression Charts")}</Typography>
      <Grid container spacing={4} style={{ overflowY: "auto" }}>
        <Grid item xs={12} sm={12}>
          <Box sx={{ height: "200px", position: "relative" }}>
            <Line
              data={{
                labels: chartData.time,
                datasets: [
                  {
                    label: "MD",
                    data: chartData.MD,
                    borderColor: "blue",
                    fill: false,
                    tension: 0.1,
                  },
                ],
              }}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  title: {
                    display: true,
                    text: "Mean Deviation (MD)",
                  },
                },
                scales: {
                  x: {
                    title: {
                      display: true,
                      text: "Time",
                    },
                  },
                  y: {
                    title: {
                      display: true,
                      text: "MD (dB)",
                    },
                  },
                },
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Box sx={{ height: "200px", position: "relative" }}>
            <Line
              data={{
                labels: chartData.time,
                datasets: [
                  {
                    label: "PSD",
                    data: chartData.PSD,
                    borderColor: "green",
                    fill: false,
                    tension: 0.1,
                  },
                ],
              }}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  title: {
                    display: true,
                    text: "Pattern Standard Deviation (PSD)",
                  },
                },
                scales: {
                  x: {
                    title: {
                      display: true,
                      text: "Time",
                    },
                  },
                  y: {
                    title: {
                      display: true,
                      text: "PSD (dB)",
                    },
                  },
                },
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Box sx={{ height: "200px", position: "relative" }}>
            <Line
              data={{
                labels: chartData.time,
                datasets: [
                  {
                    label: "RLFI",
                    data: chartData.RLFI,
                    borderColor: "red",
                    fill: false,
                    tension: 0.1,
                  },
                ],
              }}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  title: {
                    display: true,
                    text: "False Positive Percentage (RLFI)",
                  },
                },
                scales: {
                  x: {
                    title: {
                      display: true,
                      text: "Time",
                    },
                  },
                  y: {
                    title: {
                      display: true,
                      text: "RLFI (%)",
                    },
                  },
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
