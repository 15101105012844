import axios from "axios";
import {
  config,
  isResponseValid,
  pdfConfig,
  webServiceUrl,
} from "../../utils/servicesHelper";

const getPatients = async (accessToken, clinicId, signal) =>
  axios
    .get(
      `${webServiceUrl}/clinics/${clinicId}/patients`,
      config(accessToken, signal)
    )
    .then((response) => {
      const patients = isResponseValid(response);
      return patients;
    })
    .catch((error) => {
      throw error;
    });

const getPatientById = async (
  accessToken,
  clinicId,
  patientId,
  signal,
  isPdf = null
) =>
  axios
    .get(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}`,
      isPdf ? pdfConfig(signal) : config(accessToken, signal)
    )
    .then((response) => {
      const patient = isResponseValid(response);
      return patient;
    })
    .catch((error) => {
      throw error;
    });

const deletePatient = async (accessToken, clinicId, patientId) =>
  axios
    .delete(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}`,
      config(accessToken)
    )
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw error;
    });

const updatePatient = async (accessToken, clinicId, patientId, patientData) =>
  axios
    .put(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}`,
      patientData,
      config(accessToken)
    )
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw error;
    });

const addPatient = async (accessToken, clinicId, patientData) =>
  axios
    .post(
      `${webServiceUrl}/clinics/${clinicId}/patients`,
      patientData,
      config(accessToken)
    )
    .then((response) => {
      return isResponseValid(response);
    })
    .catch((error) => {
      throw error;
    });

const getComments = async (
  accessToken,
  clinicId,
  patientId,
  progressionAnalysisId
) =>
  axios
    .get(
      `${webServiceUrl}/clinics/${clinicId}/patients/${patientId}/progression-analysis/${progressionAnalysisId}/comments`,
      config(accessToken)
    )
    .then((response) => {
      const patient = isResponseValid(response);
      return patient;
    })
    .catch((error) => {
      throw error;
    });

export {
  getPatients,
  getPatientById,
  deletePatient,
  updatePatient,
  addPatient,
  getComments,
};
