import React, { useState, useEffect, useCallback } from "react";
import {
  Drawer,
  Box,
  Button,
  Stack,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import {
  addProgressAnalysis,
  getExams,
  getSubmisionData,
} from "../../../../services/Exam";
import { sortByDate } from "../../../../utils/dateHelper";
import {
  Chart,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  LineController,
} from "chart.js";
import {
  ModalCustomCancel,
  ModalCustomConfirm,
} from "../../../../styles/muiStylesHelper";
import SnackbarMessage from "../../../../components/UI/SnackbarMessage";
import ProgressionChart from "./ProgressionChart";
import ExamCard from "./ExamCard";
import FetchExam from "./FetchExam";

const ProgressAnalysis = ({ open, setOpen, clinicId, selectedPatients }) => {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const [selectedEye, setSelectedEye] = useState("Left");
  const [exams, setExams] = useState([]);
  const [selectedExams, setSelectedExams] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openAddExamSnackbar, setOpenAddExamSnackbar] = useState(false);
  const [addExamSuccess, setAddExamSuccess] = useState(false);
  const [addExamFailed, setAddExamFailed] = useState(false);

  const [chartData, setChartData] = useState({ MD: [], PSD: [], RLFI: [] });
  Chart.register(
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    LineController
  );
  const fetchExams = useCallback(async () => {
    if (!selectedPatients || selectedPatients.length !== 1) return;

    try {
      const token = await getAccessTokenSilently();
      const examsData = await getExams(token, clinicId, selectedPatients[0]);
      const sortedExams = sortByDate(examsData, "creationDate");

      const filteredExams = sortedExams.filter((exam) => {
        if (exam.completionState !== "Completed") return false;

        const matchingSections = exam.visualFieldSections?.filter(
          (section) =>
            ["FULL_THRESHOLD", "STANDARD_THRESHOLD", "FAST_THRESHOLD"].includes(
              section.algorithm
            ) && section.eye === selectedEye
        );
        return matchingSections && matchingSections.length > 0;
      });

      const mappedExams = filteredExams.map((exam) => ({
        ...exam,
        visualFieldSections: exam.visualFieldSections.filter(
          (section) => section.eye === selectedEye
        ),
      }));
      setExams(mappedExams);
    } catch (error) {
      console.error("Error fetching exams:", error);
    }
  }, [clinicId, getAccessTokenSilently, selectedPatients, selectedEye]);

  const handleCardSelect = async (exam) => {
    if (selectedExams.some((e) => e.id === exam.id)) {
      const updatedSelection = selectedExams.filter((e) => e.id !== exam.id);
      setSelectedExams(updatedSelection);
      updateCharts(updatedSelection);
    } else {
      if (selectedExams.length > 4) {
        setOpenSnackbar(true);
        return;
      }

      try {
        const token = await getAccessTokenSilently();
        const visualFieldSectionId = exam.visualFieldSections?.[0]?.id;
        const reportData = await getSubmisionData(
          token,
          clinicId,
          selectedPatients[0],
          exam.id,
          visualFieldSectionId
        );

        if (reportData) {
          const parsedReportData = JSON.parse(reportData);

          if (
            parsedReportData?.postProcessing?.retinaLogikFieldIndex !== null
          ) {
            parsedReportData.postProcessing.retinaLogikFieldIndex *= 100;
          }
          if (parsedReportData?.stats?.fixationLossPercentage != null) {
            parsedReportData.stats.fixationLossPercentage *= 100;
          }
          if (parsedReportData?.stats?.falsePositivePercentage != null) {
            parsedReportData.stats.falsePositivePercentage *= 100;
          }

          const enrichedExam = {
            ...exam,
            reportData: parsedReportData,
            visualFieldSectionId,
          };

          const updatedSelection = [...selectedExams, enrichedExam];
          setSelectedExams(updatedSelection);
          updateCharts(updatedSelection);
        }
      } catch (err) {
        console.error(`Error fetching report data for exam ${exam.id}:`, err);
      }
    }
  };

  const handleAddProgressAnalysis = async () => {
    if (selectedExams.length <= 2) {
      setOpenAddExamSnackbar(true);
      return;
    }
    const examIds = selectedExams.map((exam) => exam.id);
    const ExamData = { eye: selectedEye, examIds };
    try {
      const token = await getAccessTokenSilently();
      const response = await addProgressAnalysis(
        token,
        clinicId,
        selectedPatients[0],
        ExamData
      );
      console.log({ response });
      setAddExamSuccess(true);
      setOpen(false);
    } catch (error) {
      setAddExamFailed(true);
    }
  };
  const updateCharts = (selectedExams) => {
    const time = selectedExams.map((exam) =>
      new Date(exam.creationDate).toLocaleDateString()
    );

    const MD = selectedExams.map(
      (exam) => exam.reportData?.postProcessing?.totalMeanDeviation ?? null
    );
    const PSD = selectedExams.map(
      (exam) =>
        exam.reportData?.postProcessing?.patternStandardDeviation ?? null
    );
    const RLFI = selectedExams.map(
      (exam) => exam.reportData?.postProcessing?.retinaLogikFieldIndex ?? null
    );

    setChartData({ time, MD, PSD, RLFI });
  };

  useEffect(() => {
    fetchExams();
  }, [fetchExams, selectedEye]);
  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
            width: { xs: "95%", sm: "80%" },
            height: "100%",
          },
        }}
      >
        <Box
          sx={{
            padding: 2,
            maxWidth: "100%",
            height: "100vh",
            margin: "0 auto",
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{ marginLeft: "auto", float: "right" }}
          >
            <Button
              variant="outlined"
              sx={ModalCustomCancel}
              onClick={() => setOpen(false)}
            >
              {t("progress_analysis_cancel")}
            </Button>
            <Button
              variant="outlined"
              sx={ModalCustomConfirm}
              onClick={() => handleAddProgressAnalysis()}
            >
              {t("progress_analysis_add")}
            </Button>
          </Stack>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={4} sm={6}>
              <Box
                sx={{
                  width: "100%",
                  height: "70vh",
                  justifyContent: "center",
                  border: "1px solid #ddd",
                  borderTop: "none",
                  borderBottom: "none",
                  overflowY: "auto",
                }}
              >
                <ProgressionChart chartData={chartData} />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              lg={8}
              sm={6}
              sx={{
                width: "100%",
                height: "70vh",
                justifyContent: "center",
                border: "1px solid #ddd",
                borderTop: "none",
                borderRight: "none",
                overflowY: "auto",
                marginTop: 2,
                borderBottom: "none",
              }}
            >
              {selectedExams.length === 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <Typography variant="body1" sx={{ color: "#999" }}>
                    {t("exams_select_rule")}
                  </Typography>
                </Box>
              ) : (
                exams.map((exam) => {
                  const isSelected = selectedExams.some(
                    (e) => e.id === exam.id
                  );
                  const selectedExam = selectedExams.find(
                    (e) => e.id === exam.id
                  );

                  return (
                    <Box key={exam.id}>
                      <FetchExam
                        exam={exam}
                        isSelected={isSelected}
                        selectedExam={selectedExam}
                        handleCardSelect={handleCardSelect}
                        t={t}
                      />
                    </Box>
                  );
                })
              )}
            </Grid>
          </Grid>

          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="" style={{ marginTop: 1 }}>
              {t("progress_analysis_select_eye")}
            </Typography>
            <RadioGroup
              row
              value={selectedEye}
              onChange={(e) => setSelectedEye(e.target.value)}
              sx={{ marginLeft: 2 }}
            >
              <FormControlLabel
                value="Left"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#E2772E",
                      },
                    }}
                  />
                }
                label={t("Left")}
              />
              <FormControlLabel
                value="Right"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#E2772E",
                      },
                    }}
                  />
                }
                label={t("Right")}
              />
            </RadioGroup>
          </div>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              overflowX: "auto",
              paddingBottom: 2,
              "&::-webkit-scrollbar": { height: "8px" },
              "&::-webkit-scrollbar-thumb": {
                background: "#888",
                borderRadius: 4,
              },
              "&::-webkit-scrollbar-thumb:hover": { background: "#555" },
            }}
          >
            {exams.map((exam) => {
              const isSelected = selectedExams.some((e) => e.id === exam.id);
              const selectedExam = selectedExams.find((e) => e.id === exam.id);

              return (
                <ExamCard
                  key={exam.id}
                  exam={exam}
                  isSelected={isSelected}
                  selectedExam={selectedExam}
                  handleCardSelect={handleCardSelect}
                  t={t}
                />
              );
            })}
          </Stack>
        </Box>

        <SnackbarMessage
          open={openSnackbar}
          onClose={() => setOpenSnackbar(false)}
          success
          text={t("exams_select_rule")}
          position={{ vertical: "top", horizontal: "right" }}
        />
        <SnackbarMessage
          open={openAddExamSnackbar}
          onClose={() => setOpenAddExamSnackbar(false)}
          success
          text={t("exams_add_rule")}
          position={{ vertical: "top", horizontal: "right" }}
        />
        <SnackbarMessage
          open={addExamSuccess}
          onClose={() => setAddExamSuccess(false)}
          success={true}
          text={t("exams_add_analysis_success")}
          position={{ vertical: "top", horizontal: "right" }}
        />
        <SnackbarMessage
          open={addExamFailed}
          onClose={() => setAddExamFailed(false)}
          warning
          text={t("exams_add_analysis_failed")}
          position={{ vertical: "top", horizontal: "right" }}
        />
      </Drawer>
    </>
  );
};

export default ProgressAnalysis;
