// Library methods
import { forwardRef, useCallback } from "react";

// Styles
import styles from "./index.module.css";

// Components
import Loader from "../../../../../components/UI/Loader";

// Utilities
import {
  calculateXPaddingTotalGraph,
  calculateYPaddingTotalGraph,
  getDeviationChartPattern,
} from "../../../../../utils/examReportsHelper";
import { getVisualFieldGrid } from "../../../../../utils/numberChartHelper";

const TotalGraph = (
  { pressedPoints, eye, gridType, isLiveUpdate = null },
  ref
) => {
  const getImage = (valuePercentile = undefined, style = undefined) => {
    const defaultStyle = { width: 12, height: 14, objectFit: "fill" };
    const pattern = getDeviationChartPattern(valuePercentile);

    if (!pattern) return ".";

    return (
      <img
        src={pattern}
        alt="Total deviation value representation"
        className={styles.imageRender}
        style={!style ? defaultStyle : { ...defaultStyle, ...style }}
      />
    );
  };

  const examAllPoints = getVisualFieldGrid(gridType, eye);

  const shouldIgnoreValue = useCallback(
    (eye, spot) => {
      return (
        gridType !== "G_10_2" &&
        ((eye === "Left" && spot.x === -3 && spot.y === -1) ||
          (eye === "Left" && spot.x === -3 && spot.y === 1) ||
          (eye === "Right" && spot.x === 3 && spot.y === -1) ||
          (eye === "Right" && spot.x === 3 && spot.y === 1))
      );
    },
    [gridType]
  );

  // Show loader while data is not yet processed
  if (pressedPoints.length === 0) {
    return <Loader containerHeight="300px" />;
  }

  return (
    <main className={styles.container} ref={ref}>
      <div className={styles.vl} />
      <div className={styles.hl} />
      {examAllPoints.map((spot, i) => {
        const hasValue = pressedPoints
          .filter((point) => point.decibelValue !== null) // disconsider the points that has a decibelValue equal to null
          .find((point) => point.x === spot.x && point.y === spot.y);

        if (hasValue) return null;

        return (
          <div
            key={i}
            style={{
              position: "absolute",
              paddingLeft: calculateXPaddingTotalGraph(spot.x),
              paddingTop: calculateYPaddingTotalGraph(spot.y),
            }}
          >
            <p style={{ fontSize: "13px", marginTop: "0px" }}>
              <span style={{ marginLeft: "2px" }}>
                <span
                  style={{
                    height: "8px",
                    width: "8px",
                    marginLeft: "2px",
                    display: "none",
                  }}
                />
              </span>
            </p>
          </div>
        );
      })}
      {pressedPoints
        .filter((spot) => !spot.completed && spot.decibelValue !== null) // considering the non completed ones, with a valid decibelValue/intensity
        .map((spot, i) => {
          return (
            <div
              key={i}
              style={{
                position: "absolute",
                paddingLeft: calculateXPaddingTotalGraph(spot.x),
                paddingTop: calculateYPaddingTotalGraph(spot.y),
              }}
            >
              <p style={{ fontSize: "13px", marginTop: "0px" }}>
                {!isLiveUpdate ? (
                  "-"
                ) : (
                  <span
                    style={{
                      height: "8px",
                      width: "8px",
                      marginLeft: "2px",
                      backgroundColor: !spot.current ? "orange" : "green",
                      borderRadius: "50%",
                      display: "inline-block",
                    }}
                  />
                )}
              </p>
            </div>
          );
        })}
      {pressedPoints
        .filter((spot) => spot.completed)
        .map((spot, i) => {
          const shouldIgnore = shouldIgnoreValue(eye, spot);

          return (
            <div
              key={i}
              style={{
                position: "absolute",
                paddingLeft: calculateXPaddingTotalGraph(spot.x),
                paddingTop: calculateYPaddingTotalGraph(spot.y),
              }}
            >
              <p style={{ fontSize: "16px", marginTop: "0px" }}>
                {shouldIgnore ? "" : getImage(spot.valuePercentile)}
              </p>
            </div>
          );
        })}
    </main>
  );
};

export default forwardRef(TotalGraph);
