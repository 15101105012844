// Library methods
import { useCallback, useContext, useEffect, useState } from "react";

// MUI Components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// Utilities
import {
  getChartSpots,
  calculateNeighbours,
  getPatternDevChartSpots,
  getTotalChartSpots,
  getGazeChartData,
} from "../../utils/examReportsHelper";

import { ReportEachSectionContext } from "../../contexts/CommentContext";
import { ErrorMessageComponent } from "../../components/UI/ErrorMessage";
import html2canvas from "html2canvas";
import TotalGraph from "../Report/VisualFieldReport/EyeReport/TotalGraph";
// Components

import NewPatternGraph from "../Report/VisualFieldReport/EyeReport/NewPatternGraph";
import NumericalGraph from "../Report/VisualFieldReport/EyeReport/NumericalGraph";
import { Paper } from "@mui/material";
import { useMediaQuery } from "react-responsive";

const ProgressAnalysisEyeReport = ({ examReport, examData, isResult }) => {
  const { forBackendPdf } = false;
  const isMobile = useMediaQuery({ maxWidth: 1000 });
  const { eyeReport } = useContext(ReportEachSectionContext);
  const [chartData, setChartData] = useState({
    number: undefined,
    pattern: undefined,
    totalDev: undefined,
    patternDev: undefined,
    gaze: undefined,
  });
  const [scale, setScale] = useState(1);
  console.log({ scale });
  const [imageSrc, setImageSrc] = useState(null);
  const isNotRlFast = examReport?.exam?.algorithm !== "SCREEN_FAST";
  const handleShowImage = useCallback(async () => {
    const element = document.getElementById(`eye-report-${eyeReport}`);

    if (!element) {
      console.error("Element not found!");
      return;
    }

    const canvas = await html2canvas(element, {
      scale: 2,
    });
    const data = canvas.toDataURL("image/jpg");

    setImageSrc(data);
  }, [eyeReport]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleShowImage();
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [handleShowImage]);

  useEffect(() => {
    if (!examReport) return;
    const spots = getChartSpots(examReport);
    const finalPatternSpots = calculateNeighbours(spots);
    const { gazeSpots } = getGazeChartData(examReport);
    setChartData({
      number: spots,
      pattern: finalPatternSpots,
      totalDev: getTotalChartSpots(examReport),
      patternDev: getPatternDevChartSpots(examReport),
      gaze: gazeSpots,
    });
  }, [examReport]);

  useEffect(() => {
    const handleResize = () => {
      const scaleFactor =
        window.innerWidth < 800
          ? window.innerWidth / 820
          : window.innerWidth < 1300
            ? window.innerWidth / 1000
            : window.innerWidth < 1920
              ? window.innerWidth / 1750
              : 1;
      setScale(scaleFactor);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  console.log({ examReport, examData });
  if (eyeReport === "") return null;

  return (
    <div className="retinalogik-section" style={{ width: "100%" }}>
      {examReport?.postProcessing ? (
        <>
          {forBackendPdf && imageSrc ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                className="retinalogik-report"
                src={imageSrc}
                alt="Generated"
                width="80%"
                height="auto"
              />
            </div>
          ) : (
            <div id={`eye-report-${eyeReport}`}>
              <Grid
                container
                spacing={3}
                sx={{
                  mt: forBackendPdf && isNotRlFast ? 0 : 4,
                  justifyContent: "flex-start",
                  alignItems: "stretch",
                  width: isMobile || isResult ? "100%" : "1000px",
                  whiteSpace: "nowrap",
                }}
              >
                {isNotRlFast && (
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Paper
                      elevation={0}
                      sx={{
                        padding: 1,
                        textAlign: "center",
                        height: "100%",
                        marginLeft: isMobile ? 2 : 4,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <NewPatternGraph
                        pressedPoints={chartData.pattern || []}
                        eye={eyeReport}
                        gridType={examReport?.exam?.grid}
                        scaler={262}
                      />
                    </Paper>
                  </Grid>
                )}

                {isNotRlFast && (
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Paper
                      elevation={0}
                      sx={{
                        padding: 2,
                        textAlign: "center",
                        height: "100%",
                        marginTop: -3,
                        marginLeft: isMobile ? 1 : 2,

                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <NumericalGraph
                        processedPoints={
                          examReport?.postProcessing?.processedPoints || []
                        }
                        gridType={examReport?.exam?.grid}
                      />
                    </Paper>
                  </Grid>
                )}

                {isNotRlFast && (
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <Paper
                      elevation={0}
                      sx={{
                        padding: 2,
                        textAlign: "center",
                        height: "100%",
                        display: "flex",
                        marginLeft: isMobile ? 0 : 4,

                        flexDirection: "column",
                      }}
                    >
                      <TotalGraph
                        pressedPoints={chartData.totalDev || []}
                        eye={eyeReport}
                        gridType={examReport?.exam?.grid}
                      />
                    </Paper>
                  </Grid>
                )}

                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    MD:{" "}
                    {examData?.reportData?.postProcessing
                      ?.totalMeanDeviation !== null
                      ? Number(
                          examData?.reportData?.postProcessing
                            ?.totalMeanDeviation
                        ).toFixed(2)
                      : "N/A"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    PSD:{" "}
                    {examData.reportData.postProcessing
                      ?.patternStandardDeviation !== null
                      ? Number(
                          examData.reportData.postProcessing
                            ?.patternStandardDeviation
                        ).toFixed(2)
                      : "N/A"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    FP:{" "}
                    {examData.reportData.stats?.falsePositivePercentage
                      ? Number(
                          examData.reportData.stats?.falsePositivePercentage
                        ).toFixed(2)
                      : "N/A"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    FL:{" "}
                    {examData.reportData.stats?.fixationLossPercentage !== null
                      ? Number(
                          examData.reportData.stats?.fixationLossPercentage
                        ).toFixed(2)
                      : "N/A"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    RLFI:{" "}
                    {examData?.reportData?.postProcessing
                      ?.retinaLogikFieldIndex !== null
                      ? Number(
                          examData?.reportData?.postProcessing
                            ?.retinaLogikFieldIndex
                        ).toFixed(2)
                      : "N/A"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    FN: {examData.reportData.stats?.falseNegativeCount || "N/A"}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          )}
        </>
      ) : (
        <ErrorMessageComponent errorBit={1} />
      )}
    </div>
  );
};

export default ProgressAnalysisEyeReport;
