import SharedDeleteModal from "../../../../components/UI/SharedDeleteModal";

const DeleteExamModal = (props) => {
  return (
    <SharedDeleteModal
      {...props}
      titleKey="exams_delete_confirmation"
      descriptionKey="exams_delete_description"
      progressionAnalysisFound={props?.progressionAnalysisFound ?? false}
      progressionAnalysisFoundCount={props?.progressionAnalysisFoundCount ?? 0}
    />
  );
};

export default DeleteExamModal;
