// Library methods
import { useEffect, useMemo, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpRightAndDownLeftFromCenter } from "@fortawesome/free-solid-svg-icons";

// MUI Components
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Skeleton from "@mui/material/Skeleton";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Grid, Tooltip, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import Auth0Icon from "../../../assets/images/auth0/auth0.svg";
import ActionableSearchBar from "../ActionableSearchBar";

const PageIntro = ({
  rows,
  desktopMarginTop = 5,
  desktopMarginBottom = -2,
  pageTitle = "",
  pageTitleAdditionalComponents = "",
  bulkUploadButtonText = "",
  addButtonOnClick = () => {},
  deleteButtonOnClick = () => {},
  bulkUploadButtonOnClick = () => {},
  auth0ButtonOnClick = () => {},
  expandButtonOnClick = () => {},
  onFilterChange = () => {},
  clearSearchFilter = () => {},
  progressAnalysisOnClick = () => {},
  selectedRows = [],
  showAddButton = true,
  showDeleteButton = true,
  showBulkUploadButton = false,
  showFilterInput = true,
  showBreadcrumb = false,
  showExpandButton = false,
  BreadcrumbComponent = "",
  AddButtonIcon = AddIcon,
  BulkUploadButtonIcon = FileUploadIcon,
  isLoadingTitle = false,
  bulkUploadButtonWidth = 250,
  disableSearchButton = false,
  setCheck,
  disableDeleteButton,
  showAuth0Button = false,
  showIcons = true,
}) => {
  const { t } = useTranslation();
  // internationalization
  const [isActive, setIsActive] = useState("all");
  // small screens
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const inputRef = useRef(null);

  const [expandSearchInput, setExpandSearchInput] = useState(false);

  const expandElements = useMemo(
    () =>
      showExpandButton &&
      rows?.length > 0 &&
      (smallScreen ? (
        <div
          style={{
            fontWeight: "700",
            fontSize: "14px",
            lineHeight: "14px",
            color: "#282C3C",
          }}
          onClick={expandButtonOnClick}
        >
          {t("view_all_devices")}
        </div>
      ) : (
        <IconButton
          aria-label="expand-devices"
          size="medium"
          sx={{
            color: "#202338",
            width: 42,
            height: 42,
            backgroundColor: "rgba(0,0,0,0.3)",
            "&:hover": {
              color: "white",
              backgroundColor: "#202338",
              borderColor: "#202338",
            },
            mx: showBulkUploadButton ? 2 : 0,
          }}
          onClick={expandButtonOnClick}
        >
          <FontAwesomeIcon size="sm" icon={faUpRightAndDownLeftFromCenter} />
        </IconButton>
      )),
    [
      expandButtonOnClick,
      rows?.length,
      showBulkUploadButton,
      showExpandButton,
      smallScreen,
      t,
    ]
  );
  // hides the expanded search input when search button gets disabled
  // search button is usually disabled when there is no data to search, or, when data is being fetched
  useEffect(() => {
    if (!disableSearchButton) return;
    setExpandSearchInput(false);
  }, [disableSearchButton]);

  const MobileContent = () => (
    <Box
      display={{ xs: "flex", sm: "none" }}
      justifyContent={"space-between"}
      alignItems={"center"}
      marginTop={{ xs: 2 }}
    >
      <Box
        display={{ xs: "flex", sm: "none" }}
        alignItems={"center"}
        marginTop={{ xs: 2 }}
      >
        <Typography
          component="div"
          variant="h7"
          sx={{
            fontWeight: "bold",
            fontSize: { sm: "16px", lg: "20px" },
          }}
          textOverflow={"ellipsis"}
          overflow={"hidden"}
          whiteSpace={"nowrap"}
        >
          {isLoadingTitle ? (
            <Skeleton sx={{ width: 240, height: 42 }} />
          ) : (
            pageTitle
          )}
        </Typography>
        {pageTitleAdditionalComponents}
      </Box>

      <Grid
        item
        display={{ xs: "flex", sm: "none" }}
        justifyContent={"right"}
        sm={6}
        xs={12}
      >
        {Boolean(showAuth0Button) && (
          <IconButton
            aria-label="auth0-icon"
            size="medium"
            sx={{
              color: "#202338",
              width: 34,
              height: 34,
              backgroundColor: "rgba(0,0,0,0.3)",
              "&:hover": {
                color: "white",
                backgroundColor: "#202338",
                borderColor: "#202338",
              },
              // mx: showBulkUploadButton ? 2 : 0,
            }}
            onClick={auth0ButtonOnClick}
          >
            <img src={Auth0Icon} alt="auth0-icon" />
          </IconButton>
        )}
        {showFilterInput && (
          <>
            {expandSearchInput ? (
              <TextField
                placeholder="Search..."
                id="search"
                inputRef={inputRef}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  style: {
                    backgroundColor: "#EFEFEF",
                    color: "#202338",
                    background: "#EFEFEF",
                    height: 34,
                    width: 150,
                    border: "0.531248px solid #282C3C",
                    borderRadius: 5.31248,
                    fontSize: 16,
                    marginRight: 10,
                  },
                  startAdornment: (
                    <SearchIcon sx={{ mr: 1, ml: 1 }} size={"small"} />
                  ),
                  endAdornment: (
                    <CancelIcon
                      onClick={() => {
                        clearSearchFilter();
                        if (inputRef.current) {
                          inputRef.current.value = "";
                        }
                        setExpandSearchInput(false);
                      }}
                      cursor={"pointer"}
                      sx={{ mr: 1, ml: 1 }}
                    />
                  ),
                }}
                onChange={onFilterChange}
                sx={{ width: { sm: 150, md: 150, lg: 250 }, mb: 0 }}
                data-testid="search-box-page-intro"
              />
            ) : null}

            {!expandSearchInput && (
              <IconButton
                aria-label="search"
                size="medium"
                sx={{
                  color: "#202338",
                  width: 34,
                  height: 34,
                  backgroundColor: "rgba(0,0,0,0.3)",
                  "&:hover": {
                    color: "white",
                    backgroundColor: "#202338",
                    borderColor: "#202338",
                  },
                  mx: 1,
                }}
                disabled={disableSearchButton}
                onClick={() => {
                  // if clicking this button when filter input is showing up, clear the filter
                  if (expandSearchInput) clearSearchFilter();
                  setExpandSearchInput((prev) => !prev);
                }}
              >
                <SearchIcon fontSize="inherit" />
              </IconButton>
            )}
          </>
        )}
        {showAddButton && (
          <IconButton
            aria-label="add"
            size="medium"
            sx={{
              color: "#202338",
              width: 34,
              height: 34,
              backgroundColor: "rgba(0,0,0,0.3)",
              "&:hover": {
                color: "white",
                backgroundColor: "#202338",
                borderColor: "#202338",
              },
              mx: showBulkUploadButton ? 2 : 0,
            }}
            onClick={addButtonOnClick}
          >
            <AddButtonIcon fontSize="inherit" />
          </IconButton>
        )}
        {selectedRows.length > 0 && showDeleteButton && (
          <Tooltip
            title={t("exmas_with_device_delete_error")}
            disableHoverListener={!disableDeleteButton}
          >
            <span>
              <IconButton
                aria-label="search"
                size="medium"
                sx={{
                  color: "#C11717",
                  width: 34,
                  height: 34,
                  backgroundColor: "rgba(193, 23, 17, 0.4)",
                  "&:hover": {
                    color: "white",
                    backgroundColor: "#202338",
                    borderColor: "#202338",
                  },
                  mx: 1,
                }}
                disabled={disableSearchButton || disableDeleteButton}
                onClick={deleteButtonOnClick}
              >
                <DeleteForeverIcon fontSize="inherit" />
              </IconButton>
            </span>
          </Tooltip>
        )}
        {expandElements}
      </Grid>
    </Box>
  );

  const DesktopContent = () => (
    <Box
      display={{ xs: "none", sm: "flex" }}
      justifyContent="space-between"
      alignItems="center"
    >
      <Box
        width={
          !disableDeleteButton ? "82%" : !disableSearchButton ? "88%" : "95%"
        }
        display="flex"
        justifyContent="left"
        alignItems="center"
      >
        <Box display="flex" justifyContent="left" alignItems="center" gap={1}>
          <Typography
            component="div"
            variant="h7"
            sx={{
              fontWeight: "bold",
              fontSize: { sm: "16px", lg: "20px" },
            }}
            textOverflow={"ellipsis"}
            overflow={"hidden"}
            whiteSpace={"nowrap"}
          >
            {isLoadingTitle ? (
              <Skeleton sx={{ width: 240, height: 42 }} />
            ) : (
              pageTitle
            )}
          </Typography>
          {pageTitleAdditionalComponents}
        </Box>

        {rows?.length > 0 && (
          <Box
            display="flex"
            justifyContent="left"
            alignItems="center"
            ml={{ sm: 4, md: 3, lg: 4 }}
            gap={{ sm: 1.5, md: 0.5, lg: 1.5 }}
          >
            <Typography
              component="div"
              variant="p"
              sx={{
                fontWeight: isActive === "all" ? "700" : "400",
                borderBottom: isActive === "all" ? "1px solid #282C3C" : "none",
                fontSize: "14px",
                cursor: "pointer",
              }}
              onClick={() => {
                setCheck("all");
                setIsActive("all");
              }}
            >
              {isLoadingTitle ? (
                <Skeleton sx={{ width: 240, height: 42 }} />
              ) : (
                t("word_all")
              )}
            </Typography>
            <Typography
              component="div"
              variant="p"
              sx={{
                fontWeight: isActive === "unlinked" ? "700" : "400",
                borderBottom:
                  isActive === "unlinked" ? "1px solid #282C3C" : "none",
                fontSize: "14px",
                cursor: "pointer",
              }}
              onClick={() => {
                setCheck("unlinked");
                setIsActive("unlinked");
              }}
            >
              {isLoadingTitle ? (
                <Skeleton sx={{ width: 240, height: 42 }} />
              ) : (
                t("word_unlinked")
              )}
            </Typography>
            <Typography
              component="div"
              variant="p"
              sx={{
                fontWeight: isActive === "linked" ? "700" : "400",
                borderBottom:
                  isActive === "linked" ? "1px solid #282C3C" : "none",
                fontSize: "14px",
                cursor: "pointer",
              }}
              onClick={() => {
                setCheck("linked");
                setIsActive("linked");
              }}
            >
              {isLoadingTitle ? (
                <Skeleton sx={{ width: 240, height: 42 }} />
              ) : (
                t("word_linked")
              )}
            </Typography>
          </Box>
        )}
      </Box>
      <Grid display="flex" sx={{ gap: 1 }} item xs={6} justifyContent={"right"}>
        {showIcons && (
          <>
            {
              <ActionableSearchBar
                placeholder={`${t("word_search")}...`}
                onSearchChange={onFilterChange}
                onClear={clearSearchFilter}
                showDeleteButton={showDeleteButton}
                disableDeleteButton={disableDeleteButton}
                deleteButtonOnClick={deleteButtonOnClick}
                showAuth0Button={Boolean(showAuth0Button)}
                auth0ButtonOnClick={auth0ButtonOnClick}
                showAddButton={showAddButton}
                addButtonOnClick={addButtonOnClick}
                selectedRows={selectedRows}
                showFilterInput={showFilterInput}
              />
            }
            {showBulkUploadButton && (
              <Button
                variant="outlined"
                sx={{
                  width: bulkUploadButtonWidth,
                  height: 50,
                  color: "#202338",
                  borderRadius: 30,
                  borderColor: "#202338",
                  borderWidth: 2,
                  backgroundColor: "rgba(0,0,0,0)",
                  "&:hover": {
                    color: "white",
                    backgroundColor: "#202338",
                    borderColor: "#202338",
                  },
                }}
                onClick={bulkUploadButtonOnClick}
              >
                <BulkUploadButtonIcon sx={{ mr: 1 }} />
                <Typography
                  variant="h6"
                  className="normal-case"
                  sx={{ fontWeight: "regular" }}
                >
                  {bulkUploadButtonText}
                </Typography>
              </Button>
            )}
          </>
        )}
        {expandElements}
      </Grid>
    </Box>
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      mb={{ xs: 0, sm: desktopMarginBottom }}
      mt={{ xs: 4, sm: desktopMarginTop }}
    >
      {showBreadcrumb && BreadcrumbComponent}
      {DesktopContent()}
      {MobileContent()}
    </Box>
  );
};

export default PageIntro;
