import { enUS, frFR, esES, zhHK, arSD, ukUA, koKR } from "@mui/material/locale";
import enUSDate from "date-fns/locale/en-US";
import frDate from "date-fns/locale/fr";
import esDate from "date-fns/locale/es";
import zhHKDate from "date-fns/locale/zh-HK";
import arSADate from "date-fns/locale/ar-SA";
import uk from "date-fns/locale/uk";
import de from "date-fns/locale/de";
// import hi from "date-fns/locale/hi";
import plDate from "date-fns/locale/pl";
import trDate from "date-fns/locale/tr";
import ja from "date-fns/locale/ja";
import it from "date-fns/locale/it";
import pt from "date-fns/locale/pt";
import el from "date-fns/locale/el";

const fallbackLng = ["en"];

const languageMap = {
  en: {
    label: "English",
    dir: "ltr",
    mui: enUS,
    datepicker: enUSDate,
    val: "English",
    name: "language_english",
  },
  fr: {
    label: "Français",
    dir: "ltr",
    mui: frFR,
    datepicker: frDate,
    val: "French",
    name: "language_french",
  },
};

const allLanguageMap = {
  ar: {
    label: "Arabic",
    dir: "ltr",
    mui: arSD,
    datepicker: enUSDate,
    val: "Arabic",
    name: "language_arabic",
  },
  en: {
    label: "English",
    dir: "ltr",
    mui: enUS,
    datepicker: enUSDate,
    val: "English",
    name: "language_english",
  },
  fr: {
    label: "Français",
    dir: "ltr",
    mui: frFR,
    datepicker: frDate,
    val: "French",
    name: "language_french",
  },
  sp: {
    label: "Spanish",
    dir: "ltr",
    mui: esES,
    datepicker: esDate,
    val: "Spanish",
    name: "language_spanish",
  },
  ca: {
    label: "Cantonese",
    dir: "ltr",
    mui: zhHK,
    datepicker: zhHKDate,
    val: "Cantonese",
    name: "language_cantonese",
  },
  da: {
    label: "Dari",
    dir: "ltr",
    mui: arSD,
    datepicker: arSADate,
    val: "Dari",
    name: "language_dari",
  },
  hi: {
    label: "Hindi",
    dir: "ltr",
    mui: arSD,
    datepicker: arSADate,
    val: "Hindi",
    name: "language_hindi",
  },
  ma: {
    label: "Mandarin",
    dir: "ltr",
    mui: arSD,
    datepicker: arSADate,
    val: "Mandarin",
    name: "language_mandarin",
  },
  ur: {
    label: "Urdu",
    dir: "ltr",
    mui: arSD,
    datepicker: arSADate,
    val: "Urdu",
    name: "language_urdu",
  },
  ua: {
    label: "Ukrainian",
    dir: "ltr",
    mui: ukUA,
    datepicker: uk,
    val: "Ukrainian",
    name: "language_ukrainian",
  },
  tl: {
    label: "Tagalog",
    dir: "ltr",
    // mui: tlTL,  can't be found
    datepicker: zhHKDate,
    val: "Tagalog",
    name: "language_tagalog",
  },
  de: {
    label: "German",
    dir: "ltr",
    mui: de,
    // datepicker: ,
    val: "German",
    name: "language_german",
  },
  pb: {
    label: "Punjabi",
    dir: "ltr",
    // mui: ,
    datepicker: arSADate,
    val: "Punjabi",
    name: "language_punjabi",
  },
  tr: {
    label: "Turkish",
    dir: "ltr",
    // mui: ,
    datepicker: trDate,
    val: "Turkish",
    name: "language_turkish",
  },
  pl: {
    label: "Polish",
    dir: "ltr",
    // mui: ,
    datepicker: plDate,
    val: "Polish",
    name: "language_polish",
  },
  jp: {
    label: "Japanese",
    dir: "ltr",
    // mui: ,
    datepicker: ja,
    val: "Japanese",
    name: "language_japanese",
  },
  it: {
    label: "Italian",
    dir: "ltr",
    // mui: ,
    datepicker: it,
    val: "Italian",
    name: "language_italian",
  },
  pt: {
    label: "Portuguese",
    dir: "ltr",
    // mui: ,
    datepicker: pt,
    val: "Portuguese",
    name: "language_portuguese",
  },
  fa: {
    label: "Persian",
    dir: "ltr",
    // mui: ,
    datepicker: pt,
    val: "Persian",
    name: "language_persian",
  },
  kr: {
    label: "Korean",
    dir: "ltr",
    // mui: ,
    datepicker: koKR,
    val: "Korean",
    name: "language_korean",
  },
  el: {
    label: "Greek",
    dir: "ltr",
    // mui: ,
    datepicker: el,
    val: "Greek",
    name: "language_greek",
  },
  gr: {
    label: "Gujarati",
    dir: "ltr",
    // mui: ,
    datepicker: el,
    val: "Gujarati",
    name: "language_gujarati",
  },

  ti: {
    label: "Tamil",
    dir: "ltr",
    // mui: ,
    datepicker: el,
    val: "Tamil",
    name: "language_tamil",
  },

  vtm: {
    label: "Vietnamese",
    dir: "ltr",
    // mui: ,
    datepicker: el,
    val: "Vietnamese",
    name: "language_vietnamese",
  },

  rs: {
    label: "Russian",
    dir: "ltr",
    // mui: ,
    datepicker: el,
    val: "Russian",
    name: "language_russian",
  },

  zl: {
    label: "Zulu",
    dir: "ltr",
    // mui: ,
    datepicker: el,
    val: "Zulu",
    name: "language_zulu",
  },
};

const availableLanguages = Object.keys(languageMap);

export { fallbackLng, languageMap, availableLanguages, allLanguageMap };
