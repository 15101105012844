import React, { useState } from "react";
import { Box, Typography, Card, CardContent, Grid } from "@mui/material";
import { Checkbox } from "@mui/material";

export default function ExamCard({
  exam,
  isSelected,
  selectedExam,
  handleCardSelect,
  t,
}) {
  const [switchGrid, setSetGrid] = useState(true);
  return (
    <Card
      key={exam.id}
      sx={{
        minWidth: isSelected ? 400 : 300,
        backgroundColor: isSelected ? "#E2772E" : "#1E1E2F",
        color: "#FFF",
        borderRadius: 2,
        boxShadow: 3,
        padding: 2,
        paddingBottom: 1,
        transition: "background-color 0.3s ease",
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
      onClick={() => {
        handleCardSelect(exam);
        setSetGrid(!switchGrid);
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          paddingBottom: 0,
          marginBottom: 0,
        }}
      >
        <Checkbox
          checked={isSelected}
          onChange={() => handleCardSelect(exam)}
          sx={{
            color: "#FFF",
            "&.Mui-checked": { color: "#fff" },
          }}
        />
        <Typography style={{ fontSize: "16px", fontWeight: "bold" }}>
          {t("progress_analysis_card_type")}:{" Fast Threshold | 24-2"}
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} style={{ marginTop: -20 }}>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: switchGrid ? "column" : "row",
              gap: 1,
              paddingBottom: 0,
              marginBottom: 0,
            }}
          >
            <Typography>
              {t("progress_analysis_exam_date")}:{" "}
              {new Date(exam.creationDate)
                .toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })
                .replace(/\//g, "-")}
            </Typography>
            <Typography variant="body2" sx="mt-12" style={{ marginTop: 5 }}>
              {t("progress_analysis_card_age")}: {exam.age}
            </Typography>
          </CardContent>
        </Grid>

        <Grid item xs={12} style={{ marginTop: "-10%" }}>
          <Grid container>
            <Grid item xs={6}>
              {isSelected && selectedExam?.reportData?.stats && (
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    paddingBottom: 0,
                  }}
                >
                  {[
                    { label: "FL", field: "fixationLossPercentage" },
                    {
                      label: "FN",
                      fields: ["falseNegativeCount", "falseNegativeMisses"],
                    },
                    { label: "FP", field: "falsePositivePercentage" },
                  ].map(({ label, field, fields }, index) => (
                    <Typography key={index}>
                      {label}:{" "}
                      {fields
                        ? `${selectedExam.reportData?.stats[fields[1]] || 0}/${selectedExam.reportData?.stats[fields[0]] || 0}`
                        : selectedExam.reportData?.stats[field] !== null
                          ? Number(
                              selectedExam.reportData?.stats[field]
                            ).toFixed(2)
                          : "N/A"}
                      {!fields && field !== "falseNegativeCount" && "%"}
                    </Typography>
                  ))}
                </CardContent>
              )}
            </Grid>

            <Grid item xs={6}>
              {isSelected && selectedExam?.reportData.postProcessing && (
                <CardContent
                  sx={{ display: "flex", flexDirection: "column", gap: 1 }}
                >
                  {exam.visualFieldSections?.map((section, index) => (
                    <Box
                      key={section.id || index}
                      display="flex"
                      flexDirection="column"
                      gap={1}
                    >
                      {[
                        { label: "PSD", field: "patternStandardDeviation" },
                        { label: "MD", field: "totalMeanDeviation" },
                        { label: "RLFI", field: "retinaLogikFieldIndex" },
                      ].map(({ label, field }, index) => (
                        <Typography key={index}>
                          {label}:{" "}
                          {selectedExam.reportData.postProcessing[field] !==
                          null
                            ? Number(
                                selectedExam.reportData.postProcessing[field]
                              ).toFixed(2)
                            : "N/A"}
                          {field === "retinaLogikFieldIndex" && "%"}
                        </Typography>
                      ))}
                    </Box>
                  ))}
                </CardContent>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}
