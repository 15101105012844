import React from "react";
import { useTranslation } from "react-i18next";
import { createTheme, useMediaQuery } from "@mui/material";

// MUI Components
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

import CommentCard from "./CommentCard";
import { useEffect } from "react";

const CommentsListV2 = ({
  isVisualField,
  isEsterman = false,
  comments,
  conditionTitles,
  commentsSectionRef,
}) => {
  const [activeComments, setActiveComments] = React.useState([]);
  const { t } = useTranslation();
  const theme = createTheme();
  const isWiderThanSmallScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const maxHeight = 120;

  useEffect(() => {
    console.log({ comments });

    setActiveComments(comments);
  }, [comments]);

  return (
    <Grid container>
      {(isVisualField || isEsterman) && <Grid item xs={1}></Grid>}
      {activeComments?.length > 0 ? (
        <>
          <Grid
            container
            item
            xs={isVisualField ? 8 : isEsterman ? 11 : 12}
            sm={isVisualField ? 9 : isEsterman ? 11 : 12}
            border={1}
            maxHeight={maxHeight}
            overflow="auto"
            ref={commentsSectionRef ? commentsSectionRef : null}
          >
            {activeComments.map((comment, index) => (
              <CommentCard
                key={index}
                username="Sample user"
                creationDate={comment.creationDate}
                comment={comment.content}
                conditions={comment.conditions}
                prevConditions={
                  index !== activeComments.length - 1
                    ? activeComments[index + 1].conditions
                    : []
                }
                conditionTitles={conditionTitles} // Pass conditionTitles as a prop
              />
            ))}
          </Grid>
          {isVisualField && (
            <Grid
              item
              xs={3}
              sm={2}
              border={1}
              borderLeft={0}
              height={maxHeight}
            >
              {Object.keys(conditionTitles).length && (
                <List>
                  {Object.keys(conditionTitles)
                    .slice(0, -1)
                    .map((key, index) => (
                      <ListItem
                        key={key}
                        disablePadding
                        sx={{ marginLeft: 0.5 }}
                      >
                        <Checkbox
                          checked={activeComments[0].conditions.includes(key)}
                          disabled
                          style={{ padding: 0 }}
                        />
                        <ListItemText
                          primary={conditionTitles[key]}
                          primaryTypographyProps={{
                            paddingLeft: isWiderThanSmallScreen ? 1 : 0,
                            fontSize: isWiderThanSmallScreen ? 16 : 14,
                          }}
                        />
                      </ListItem>
                    ))}
                </List>
              )}
            </Grid>
          )}
        </>
      ) : (
        <Grid item xs={isVisualField ? 11 : 12} pt={2} pb={2} border={1}>
          <Typography textAlign="center">{t("no_comments")}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default CommentsListV2;
