// Library methods
import { forwardRef, useCallback, useContext, useMemo } from "react";

// Styles
import styles from "./index.module.css";

// Components
import Loader from "../../../../../components/UI/Loader";
import ChartCross from "../../../../../components/UI/ChartCross";

// Utilities
import {
  calculateXPaddingNumberGraph,
  calculateYPaddingNumberGraph,
  GridTypes,
} from "../../../../../utils/examReportsHelper";
import { ReportEachSectionContext } from "../../../../../contexts/CommentContext";
import { ReportDataContext } from "../../../../../contexts/ReportDataContext";
import { EyeContext } from "../../../../../contexts/ExamPropertyContext";

const NumericalGraph = ({ processedPoints, gridType, isTotal = true }, ref) => {
  const { exam } = useContext(ReportDataContext);
  const { eyeReport, sectionId } = useContext(ReportEachSectionContext);
  const { eyeRight, eyeLeft } = useContext(EyeContext);

  const isTerminated = useMemo(
    () =>
      exam?.visualFieldSections?.find((section) => section?.id === sectionId)
        ?.completionState === "Terminated",
    [exam?.visualFieldSections, sectionId]
  );

  const shouldIgnoreValue = useCallback(
    (eye, spot) =>
      gridType !== "G_10_2" &&
      ((eye === "Left" && spot.x === -3 && (spot.y === -1 || spot.y === 1)) ||
        (eye === "Right" && spot.x === 3 && (spot.y === -1 || spot.y === 1))),
    [gridType]
  );

  const calculateStyle = (spot, eye, is24C, eyeReport) => {
    const ml = calculateXPaddingNumberGraph(spot.x);
    const mt = calculateYPaddingNumberGraph(spot.y);

    const paddingLeft = is24C
      ? 0 < spot.x && spot.x < 1
        ? ml + 6 // Adjust leftward slightly less
        : !Number.isInteger(spot.x) && spot.y === -2
          ? ml + 0
          : eye === eyeLeft && spot.x === -1 && spot.y === -2
            ? ml - 2
            : eye === eyeRight && spot.x === 1 && spot.y === -2
              ? ml + 1
              : ml - 45 // More consistent leftward adjustment
      : ml - 9;

    const paddingTop = is24C
      ? !Number.isInteger(spot.x) && spot.y === -2
        ? mt + 2
        : (eye === eyeLeft || eye === eyeRight) && spot.y === -2
          ? mt + 1
          : spot.y === -1.33
            ? mt + 2
            : mt - 25 // Consistent upward adjustment
      : mt - 25; // General upward adjustment for non-24C

    return { paddingLeft, paddingTop };
  };

  const calculateFontSize = (spot, is24C, eyeReport) => {
    return is24C
      ? !Number.isInteger(spot.x) && spot.y === -2
        ? "7px"
        : ((eyeReport === eyeLeft && spot.x === -1) ||
              (eyeReport === eyeRight && spot.x === 1)) &&
            spot.y === -2
          ? "9px"
          : !Number.isInteger(spot.x) || !Number.isInteger(spot.y)
            ? "8px"
            : "11px"
      : "11px";
  };

  if (!processedPoints.length) {
    return <Loader containerHeight="252px" />;
  }

  return (
    <main className={styles.container} ref={ref} style={{ marginTop: 10 }}>
      <ChartCross gridType={gridType} width={300} height={270} />
      {processedPoints.map((point, i) => {
        const spot = point?.Spot?.position;
        const shouldIgnore = shouldIgnoreValue(eyeReport, spot);
        const style = calculateStyle(
          spot,
          eyeReport,
          gridType === GridTypes.G24C,
          eyeReport
        );

        return (
          <div key={i} style={{ position: "absolute", ...style }}>
            {!shouldIgnore && (
              <p
                style={{
                  fontSize: calculateFontSize(
                    spot,
                    gridType === GridTypes.G24C,
                    eyeReport
                  ),
                  marginTop: "0px",
                }}
              >
                {isTerminated
                  ? "-"
                  : Math.floor(
                      isTotal ? point?.TotalDeviation : point?.PatternDeviation
                    )}
              </p>
            )}
          </div>
        );
      })}
    </main>
  );
};

export default forwardRef(NumericalGraph);
