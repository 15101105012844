// ProgressionCharts.js
import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Line } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  LineController,
} from "chart.js";

const ProgressionCharts = ({ examData }) => {
  const [chartDataList, setChartDataList] = useState([]);

  const { t } = useTranslation();
  Chart.register(
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    LineController
  );

  useEffect(() => {
    if (!examData || !examData.length) return;
    const uniqueData = examData.reduce(
      (acc, exam) => {
        const date = new Date(exam.creationDate).toLocaleDateString();
        const MD = exam.reportData?.postProcessing?.totalMeanDeviation ?? null;
        const PSD =
          exam.reportData?.postProcessing?.patternStandardDeviation ?? null;
        const RLFI =
          exam.reportData?.postProcessing?.retinaLogikFieldIndex ?? null;

        if (!acc.time.includes(date)) {
          acc.time.push(date);

          acc.MD.push(MD !== null ? MD : undefined);
          acc.PSD.push(PSD !== null ? PSD : undefined);
          acc.RLFI.push(RLFI !== null ? RLFI : undefined);
        }

        return acc;
      },
      { time: [], MD: [], PSD: [], RLFI: [] }
    );

    setChartDataList(uniqueData);
  }, [examData]);
  return (
    <>
      <Typography variant="h6">{t("Progression Charts")}</Typography>

      <Grid container spacing={3} style={{ marginTop: 4 }}>
        {["MD", "PSD", "RLFI"].map((key, index) => (
          <Grid item xs={12} md={4} key={key}>
            <Line
              data={{
                labels: chartDataList.time,
                datasets: [
                  {
                    label: key,
                    data: chartDataList[key],
                    borderColor:
                      index === 0 ? "blue" : index === 1 ? "green" : "red",
                    fill: false,
                    tension: 0.1,
                  },
                ],
              }}
              options={{
                responsive: true,
                plugins: {
                  title: {
                    display: true,
                    text:
                      key === "MD"
                        ? "Mean Deviation (MD)"
                        : key === "PSD"
                          ? "Pattern Standard Deviation (PSD)"
                          : "Retina Logik Field Index (RLFI)",
                  },
                },
                scales: {
                  x: {
                    title: { display: true, text: "Time" },
                  },
                  y: {
                    title: { display: true, text: `${key} (dB)` },
                  },
                },
              }}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default ProgressionCharts;
