// Library methods
import { useMemo } from "react";

// Components
import QuadrantBuilder from "./QuadrantBuilder";
import ChartCross from "../../../../../components/UI/ChartCross";
import Loader from "../../../../../components/UI/Loader";

// Utilities
import { buildQuadrantGrid } from "../../../../../utils/examReportsHelper";

// Styles
import styles from "./index.module.css";

const NewPatternGraph = ({ pressedPoints, eye, gridType, scaler }) => {
  const { forBackendPdf } = true;
  const scale = forBackendPdf ? 1 : 1;

  // method to order the points by the event X value asc
  const compareXAxis = (a, b) => {
    const xPositionA = a.x;
    const xPositionB = b.x;

    if (xPositionA < xPositionB) {
      return -1;
    }
    if (xPositionA > xPositionB) {
      return 1;
    }
    return 0;
  };

  const sortedPoints = useMemo(() => {
    const sortPoints = (points) => points.sort(compareXAxis);

    return {
      topLeft: sortPoints(pressedPoints.filter(({ x, y }) => y >= 0 && x <= 0)),
      topRight: sortPoints(pressedPoints.filter(({ x, y }) => y >= 0 && x > 0)),
      bottomLeft: sortPoints(
        pressedPoints.filter(({ x, y }) => y < 0 && x <= 0)
      ),
      bottomRight: sortPoints(
        pressedPoints.filter(({ x, y }) => y < 0 && x > 0)
      ),
    };
  }, [pressedPoints]);

  // should be an array of arrays, based on the columns and rows defined on the quadrant builder
  const quadrantValues = useMemo(() => {
    const buildGrid = (points, sectionSide) =>
      points.length
        ? buildQuadrantGrid(gridType, sectionSide, eye, points)
        : [];

    return {
      topLeft: buildGrid(sortedPoints.topLeft, "Left"),
      topRight: buildGrid(sortedPoints.topRight, "Right"),
      bottomLeft: buildGrid(sortedPoints.bottomLeft, "Left"),
      bottomRight: buildGrid(sortedPoints.bottomRight, "Right"),
    };
  }, [gridType, eye, sortedPoints]);

  // Show loader while data is not yet processed
  if (
    quadrantValues.bottomRight.length === 0 ||
    quadrantValues.bottomLeft.length === 0 ||
    quadrantValues.topRight.length === 0 ||
    quadrantValues.topLeft.length === 0
  ) {
    return <Loader containerHeight="312px" />;
  }

  return (
    <div
      style={{
        "--new-pattern-cross-length": `${scaler ? scaler : 312 * scale}px`,
        "--new-pattern-half-length": `calc(${scaler ? scaler : 312 * scale}px / 2)`,
      }}
      className={styles.container}
    >
      <ChartCross
        gridType={gridType}
        width={scaler ? scaler : 312 * scale}
        height={scaler ? scaler : 312 * scale}
        tickSize={6 * scale}
      />
      {/* Top left section */}
      <div className={styles.topLeftSection}>
        {/* Using the default props for the quadrant builder, except for the gridValues */}
        <QuadrantBuilder
          gridValues={quadrantValues.topLeft}
          mirrorHorizontally
        />
      </div>
      {/* Top right section */}
      <div className={styles.topRightSection}>
        {/* Using the default props for the quadrant builder, except for the gridValues */}
        <QuadrantBuilder gridValues={quadrantValues.topRight} />
      </div>
      {/* Bottom left section */}
      <div className={styles.bottomLeftSection}>
        {/* Using the default props for the quadrant builder, except for the gridValues */}
        <QuadrantBuilder
          gridValues={quadrantValues.bottomLeft}
          mirrorHorizontally
          mirrorVertically
        />
      </div>
      {/* Bottom right section */}
      <div className={styles.bottomRightSection}>
        {/* Using the default props for the quadrant builder, except for the gridValues */}
        <QuadrantBuilder
          gridValues={quadrantValues.bottomRight}
          mirrorVertically
        />
      </div>
    </div>
  );
};

export default NewPatternGraph;
