// Library methods
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";

// MUI Components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";

// Styles
import {
  CustomTextFieldOutlined,
  CustomTextFieldOutlinedInputProps,
  ModalCustomCancel,
  ModalPaperProps,
  ModalCustomConfirmDelete,
  ModalCustomConfirm,
  ModalCustomConfirmIconHandler,
  ModalDialogActions,
} from "../../../../styles/muiStylesHelper";
import CustomDatePicker from "../../../../components/UI/CustomDatePIcker";
import { useCallback, useMemo } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getLocale } from "../../../../utils/localeHelper";
import i18next from "i18next";
import SearchBox from "../../../../components/UI/SearchBox";

const EditDeviceModal = ({
  open,
  onClose,
  onConfirm,
  onCancel,
  clinicsOptions = [],
  deviceClinic,
  deviceAlias,
  setDeviceClinic,
  setDeviceAlias,
  checkClinicExams,
  deviceExpiryDate,
  clinicAssignmentExpiryDate,
  setDeviceExpiryDate,
  setClinicAssignmentExpiryDate,
  setUniversalDeviceId,
  universalDeviceId,
}) => {
  // internationalization
  const { t } = useTranslation();

  const shouldDisable = useMemo(
    () =>
      (deviceClinic?.name &&
        !clinicsOptions.some(
          (option) => option?.name === deviceClinic?.name
        )) ||
      clinicsOptions.length === 0 ||
      deviceAlias === "",
    [clinicsOptions, deviceAlias, deviceClinic?.name]
  );
  const handleChangeClinicAssignment = useCallback(
    (newValue) => {
      setClinicAssignmentExpiryDate(newValue);
    },
    [setClinicAssignmentExpiryDate]
  );
  const handleChangeDeviceExp = useCallback(
    (newValue) => {
      setDeviceExpiryDate(newValue);
    },
    [setDeviceExpiryDate]
  );

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={getLocale(i18next.language).datepicker}
      local
    >
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: { ...ModalPaperProps, width: { xs: "100%", md: "30%" } },
        }}
      >
        <DialogContent>
          <Box
            padding={1}
            display="flex"
            flexDirection="row"
            gap={1}
            alignItems="center"
          >
            <FontAwesomeIcon icon={faPenToSquare} size="lg" />
            <Typography
              style={{ fontWeight: "bold" }}
              variant="h5"
              component="h2"
            >
              {t("device_edit_modal_title")}
            </Typography>
          </Box>
          <Box padding={1}>
            {/* device name field */}
            <TextField
              id="outlined-basic"
              value={deviceAlias}
              onChange={(e) => setDeviceAlias(e.target.value)}
              sx={() => CustomTextFieldOutlined({ mt: "2rem", mb: "1.25rem" })}
              label={t("device_modal_device_name")}
              InputProps={{
                sx: CustomTextFieldOutlinedInputProps,
              }}
              variant="outlined"
              fullWidth
              color="warning"
            />
            {/* clinic selector */}
            <SearchBox
              data={clinicsOptions}
              label={t("device_edit_modal_clinic")}
              state={deviceClinic}
              setState={setDeviceClinic}
              mb="1.25rem"
            />
            <Box height={60}>
              <CustomDatePicker
                date={deviceExpiryDate}
                labelTitle="device_modal_device_expiry_date"
                handleChange={handleChangeDeviceExp}
              />
            </Box>
            <CustomDatePicker
              date={clinicAssignmentExpiryDate}
              labelTitle="device_modal_clinic_assignment_expiry_date"
              handleChange={handleChangeClinicAssignment}
              shouldDisable={!deviceClinic || !deviceClinic?.id}
            />

            <TextField
              id="outlined-basic"
              value={universalDeviceId}
              onChange={(e) => setUniversalDeviceId(e.target.value)}
              sx={() => CustomTextFieldOutlined({ mt: "2rem", mb: "1.25rem" })}
              label={t("device_modal_universalDeviceId")}
              InputProps={{
                sx: CustomTextFieldOutlinedInputProps,
              }}
              variant="outlined"
              fullWidth
              color="warning"
              shouldDisable={!deviceClinic || !deviceClinic?.id}
            />

            {checkClinicExams() && (
              <Typography
                id="modal-modal-description"
                variant="body1"
                component="div"
                mt={2}
              >
                {t("device_edit_modal_confirmation")}
              </Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={ModalDialogActions}>
          <Button variant="text" sx={ModalCustomCancel} onClick={onCancel}>
            {t("button_cancel")}
          </Button>
          {checkClinicExams() ? (
            <Button
              variant="outlined"
              sx={{
                ...ModalCustomConfirmDelete(),
                ...ModalCustomConfirmIconHandler(),
              }}
              disabled={clinicsOptions.length === 0 || deviceAlias === ""}
              onClick={onConfirm}
              color="error"
            >
              <span
                className="material-icons mr-1"
                style={{ fontSize: "1.3rem" }}
              >
                warning
              </span>
              {t("button_confirm")}
            </Button>
          ) : (
            <Button
              variant="outlined"
              sx={{
                ...ModalCustomConfirm(),
                ...ModalCustomConfirmIconHandler(),
              }}
              disabled={shouldDisable}
              onClick={onConfirm}
            >
              {t("button_edit")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default EditDeviceModal;
