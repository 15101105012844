import {
  Box,
  createTheme,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useCallback, useContext, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ReportInfoShared } from "../../components/UI/ReportInfoShared";
import { ValidatePDFContext } from "../../contexts/ValidatePDFContext";
import eyeLogo from "../../assets/images/logos/eyelogo.png";
import useReportInfoFontSize from "../../hooks/useReportInfoLetterSize";
import { CommentModalContext } from "../../contexts/CommentContext";
import ProgressAnalysisEyeReport from "./ProgressAnaysisEyeReport";
import ProgressionCharts from "./chartData";
import ReportInfoHeaderComponentAnalysis from "../../components/UI/ReportInfoSharedAnalysis";
import CommentsListV2 from "../../components/UI/CommentsListV2";

const ProgressionAnalysisReportContainer = ({
  report,
  patient,
  clinicId,
  progressionAnalysisId,
  comments,
  conditionTitles,
  getAllComments,
  commentsSectionRef,
}) => {
  const { t } = useTranslation();
  const { forBackendPdf } = useContext(ValidatePDFContext);
  const { setCommentModalSectionId } = useContext(CommentModalContext);
  const { dynamicFontSize } = useReportInfoFontSize();
  const bpTheme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1300,
        xl: 1700,
      },
    },
  });

  const isWiderThanSmallScreen = useMediaQuery(bpTheme.breakpoints.up("sm"));
  const handleClickOpen = useCallback(() => {
    setCommentModalSectionId(report.id);
  }, [report, setCommentModalSectionId]);
  const gridSizeData = useMemo(
    () => [
      { sm: 4, md: 4, lg: 4, xl: 4 },
      { sm: 5, md: 5.5, lg: 5, xl: 5.5 },
      { sm: 3, md: 2.5, lg: 3, xl: 2.5 },
    ],
    []
  );
  const infoData = useMemo(
    () => [
      [
        {
          title: t("word_eye"),
          body: report?.eye === "Right" ? t("word_right") : t("word_left"),
        },
      ],
      [
        {
          title: t("word_sex"),
          body: patient?.sex,
        },
      ],
      [
        {
          title: t("patients_edit_modal_birth"),
          body: patient?.dateOfBirth?.split("T")?.[0] ?? t("word_na"),
          isBlack: true,
        },
      ],
    ],
    [patient?.dateOfBirth, report?.eye, patient?.sex, t]
  );

  useEffect(() => {
    console.log("comments>>>>", comments);
  }, [comments]);

  const ReportInfo = useMemo(
    () => (
      <Grid
        container
        columnSpacing={2}
        mt={5}
        sx={{ display: "flex", alignItems: "start" }}
      >
        <Grid container item xs={12}>
          {forBackendPdf && (
            <Grid
              item
              xs={1}
              style={{
                paddingLeft: 15,
                alignItems: "center",
              }}
            >
              <img
                src={eyeLogo}
                alt="eye logo"
                style={{ width: "80%", height: "auto" }}
              />
            </Grid>
          )}

          <Grid container item xs={forBackendPdf ? 11 : 12} mb={1.5}>
            <Grid
              item
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              border={1}
              xs={12}
            >
              <Grid
                item
                sx={{
                  display: "flex",
                  background: "black",
                  width: 70,
                  height: "100%",
                  color: "white",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                mr={isWiderThanSmallScreen ? 2 : 1}
                xs={1.5}
                sm={1}
              >
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  {report?.eye === "Right" ? "OD" : "OS"}
                </Typography>
              </Grid>
              {
                <ReportInfoHeaderComponentAnalysis
                  isWiderThanSmallScreen={isWiderThanSmallScreen}
                  shouldReduceFontSizeInPdf={false}
                  dynamicFontSize={dynamicFontSize}
                  patient={patient}
                  clinicId={clinicId}
                  name={
                    <>
                      {t("devices_table_name")}:{" "}
                      {patient?.firstName + " " + patient?.lastName}
                    </>
                  }
                  rightText={t("progression_analysis")}
                  handleClickOpen={handleClickOpen}
                  progressionAnalysisId={progressionAnalysisId}
                  getAllComments={getAllComments}
                  commentsSectionRef={commentsSectionRef}
                />
              }
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          itemy
          display="flex"
          justifyContent={{ xs: "space-around", md: "space-between" }}
        >
          <ReportInfoShared data={infoData} gridSizeData={gridSizeData} />
          <ProgressionCharts examData={report} />
          {report.map((item) => (
            <ProgressAnalysisEyeReport
              key={item.id}
              examReport={item.reportData}
              examData={item}
              isResult={true}
            />
          ))}
        </Grid>

        {comments && comments?.length > 0 && (
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ my: { sx: 0, sm: 0, md: 5 } }}
            spacing={3}
          >
            <Grid container item xs={12} mt={2}>
              <CommentsListV2
                isVisualField={false}
                comments={comments ?? []}
                conditionTitles={conditionTitles}
                commentsSectionRef={commentsSectionRef}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    ),
    [
      dynamicFontSize,
      forBackendPdf,
      gridSizeData,
      handleClickOpen,
      infoData,
      clinicId,
      patient,
      isWiderThanSmallScreen,
      report,
      t,
      comments,
      commentsSectionRef,
    ]
  );

  return (
    <>
      {ReportInfo}
      {/* <Box height={"70vh"}></Box> */}
    </>
  );
};

export default ProgressionAnalysisReportContainer;
